import gql from 'graphql-tag'

export const currentUserQuery = gql`
  query currentUser {
    viewer {
      id
      calendarUrl
      contactEmail
      name
      userRole
      intercomHash
      stripeCustomer {
        id
      }
      alternates {
        id
        group {
          id
          name
          loginUrl
        }
        userRole
      }
      avatar {
        id
        color
        initials
        imageUrl(height: 64, width: 64)
      }
      files {
        id
        imageUrl(height: 128, width: 192)
        mimeType
        fileUrl
      }
      group {
        id
        name
        slug
        startsAt
        endsAt
        allowGroupSessions
        allowMasterclasses
      }
      mentorlyAdmin
      menteeSessionsRemaining
      mentorSessionsRemaining
      accounts {
        id
        name
        slug
        groups {
          id
          customDomain
          name
          slug
        }
      }
      legacyGroup
      mentor
      allowGroupSessions
      tags {
        id
        key
        name
        nameFr
        nameEn
      }
      slug
      managedGroups {
        id
        languages {
          code
        }
      }
      timezone
    }
  }
`

export const currentFullUserQuery = gql`
  query currentUser {
    disciplines {
      id
      name
      subdisciplines {
        id
        name
      }
    }
    viewer {
      id

      accounts {
        id
        name
        slug
        groups {
          id
          name
          slug
        }
      }
      avatar {
        id
        color
        initials
        imageUrl(height: 80, width: 80)
      }
      bookings {
        id
        startTime
        endTime
        mentor {
          id
        }
      }
      calendarUrl
      contactEmail
      company
      description
      onboardingPercent
      profilePercent
      discipline {
        id
        name
        slug
      }
      disciplines {
        id
        name
      }
      email
      experience
      files {
        id
        imageUrl(height: 128, width: 192)
        mimeType
        fileUrl
        type
      }
      group {
        id
        name
        slug
        disciplines {
          id
          name
          subdisciplines {
            id
            name
          }
        }
        files {
          id
          imageUrl(height: 128, width: 192)
          mimeType
          fileUrl
          type
        }
      }
      intercomHash
      languages {
        id
        code
        name
      }
      location
      name
      mentor
      rates
      cancellationPolicy
      phoneNumber
      preferredLanguage {
        id
        code
        name
      }
      profileImageUrl(height: 160, width: 160)
      pronouns
      role
      subdisciplines {
        id
        name
        slug
      }
      timezone
      skills
      hardSkills
      softSkills
      shortTermGoals
      longTermGoals
      slug
      surveyResult {
        id
        data
      }
      userRole
      website
      welcomeMessage
      socialLinks {
        type
        url
      }
      behanceLink
      dribbbleLink
      facebookLink
      instagramLink
      linkedinLink
      twitterLink
      vimeoLink
      youtubeLink
    }
  }
`

export const benefitContentsQuery = gql`
  query benefitContents($locale: String!) {
    benefitContents {
      id
      title(locale: $locale)
      body(locale: $locale, format: "html")
      imageUrl(width: 124, height: 124)
    }
  }
`

export const faqSearchQuery = gql`
  query faqSearch($locale: String!, $search: String) {
    faqContents(locale: $locale, search: $search) {
      id
      question(locale: $locale)
      answer(locale: $locale, format: "html")
    }
  }
`

export const faqContentsQuery = gql`
  query faqCategories($locale: String!) {
    pageContent(id: "b2b.faq.intro") {
      title(locale: $locale)
      description(locale: $locale)
      body(locale: $locale, format: "html", headerOffset: 0)
    }
    faqCategories {
      general {
        id
        question(locale: $locale)
        answer(locale: $locale, format: "html")
      }
      pricing {
        id
        question(locale: $locale)
        answer(locale: $locale, format: "html")
      }
      payment {
        id
        question(locale: $locale)
        answer(locale: $locale, format: "html")
      }
      security {
        id
        question(locale: $locale)
        answer(locale: $locale, format: "html")
      }
    }
  }
`

export const pageContentQuery = gql`
  query pageContent($id: ID!, $locale: String!, $headerOffset: Int = 1) {
    pageContent(id: $id) {
      title(locale: $locale)
      description(locale: $locale)
      body(locale: $locale, format: "html", headerOffset: $headerOffset)
    }
  }
`

export const partnerContentsQuery = gql`
  query partnerContents($locale: String!) {
    partnerContents {
      id
      name(locale: $locale)
      url(locale: $locale)
      imageUrl(locale: $locale, width: 124, height: 124)
    }
  }
`

export const pricingContentsQuery = gql`
  query pricingContents($locale: String!) {
    pageContent(id: "b2b.pricing.intro") {
      title(locale: $locale)
      description(locale: $locale)
      body(locale: $locale, format: "html", headerOffset: 0)
    }
    planContents {
      id
      name(locale: $locale)
      description(locale: $locale)
      users(locale: $locale)
      mainFeatures(locale: $locale)
      price(locale: $locale)
    }
    featureContents {
      id
      name(locale: $locale)
      plan0
      plan1
      plan2
    }
  }
`

export const teamContentsQuery = gql`
  query teamCategories($locale: String!) {
    pageContent(id: "b2b.about.team") {
      title(locale: $locale)
      description(locale: $locale)
      body(locale: $locale, format: "html", headerOffset: 0)
    }
    teamCategories {
      founders {
        id
        name
        imageUrl(resizingType: "fill", width: 256, height: 256)
        title(locale: $locale)
      }
      advisors {
        id
        name
        imageUrl
        title(locale: $locale)
      }
    }
  }
`

export const testimonialContentsQuery = gql`
  query testimonialContents($locale: String!, $category: String) {
    testimonialContents(category: $category) {
      id
      category
      body(locale: $locale, format: "html")
      name(locale: $locale)
      imageUrl(width: 124, height: 124)
    }
  }
`

const useCaseFields = gql`
  fragment useCaseFields on UseCaseContent {
    id
    name(locale: $locale)
    organization(locale: $locale)
    body(locale: $locale, format: "html")
    quote {
      id
      name(locale: $locale)
      body(locale: $locale, format: "html")
    }
    videoUrl(locale: $locale)
  }
`

export const useCaseContentsQuery = gql`
  query useCaseContents($locale: String!) {
    useCaseCategories {
      communities {
        ...useCaseFields
      }
      schools {
        ...useCaseFields
      }
      networks {
        ...useCaseFields
      }
      specialProgramming {
        ...useCaseFields
      }
    }
    useCaseContents {
      ...useCaseFields
    }
  }
  ${useCaseFields}
`

export const groupProgramQuery = gql`
  query GroupPrograms($groupId: ID) {
    group(id: $groupId) {
      id
      name
      tag
      endsAt
      startsAt
    }
  }
`

export const groupEssentialsQuery = gql`
  query GroupEssentials($id: ID, $locale: String) {
    group(id: $id) {
      id
      backgroundImages {
        id
        imageUrl(width: 1000)
      }

      customDomain
      allowGroupSessions
      allowMasterclasses
      autoAcceptBookingRequests
      disciplines {
        id
        name(locale: $locale)
        slug
        subdisciplines(locale: $locale) {
          id
          name(locale: $locale)
          slug
        }
      }
      endsAt
      files {
        id
        imageUrl(height: 128, width: 192)
        fileUrl
        type
      }
      legacy
      marketplace
      languages {
        code
      }
      logoImage(locale: $locale) {
        imageUrl(width: 500)
      }
      manualMatching
      autoMatching
      meetingProvider
      mentorMaxSessions
      menteeMaxSessions
      name
      sessionLengths
      slug
      startsAt
      styles {
        accentColor
        accentTextColor
        backgroundColor
        backgroundTextColor
        highlightColor
        fontLink
        fontName
        titleFontLink
        titleFontName
      }
      subtitle(locale: $locale)
      tag
      title(locale: $locale)
      url
      whiteLabel
      pageLogoImage(locale: $locale) {
        imageUrl(height: 100)
      }
      partnerLogoImages(locale: $locale) {
        id
        imageUrl(height: 192)
        url
      }
    }
  }
`

export const groupScheduleQuery = gql`
  query GroupSchedule($groupId: ID, $locale: String) {
    group(id: $groupId) {
      id
      name
      tag
      slug
      info(locale: $locale)
      logoHeader(locale: $locale)
      title(locale: $locale)
      url
      startsAt
      endsAt
      disciplines {
        id
        name(locale: $locale)
        slug
      }
      mentorMaxSessions
      menteeMaxSessions
      styles {
        accentColor
        accentTextColor
        backgroundColor
        backgroundTextColor
        highlightColor
        fontLink
        fontName
        titleFontLink
        titleFontName
      }
    }
  }
`

export const groupHomeQuery = gql`
  query GroupHome($id: ID, $locale: String) {
    group(id: $id) {
      id
      name
      tag
      slug
      aboutTitle(locale: $locale)
      aboutSubtitle(locale: $locale)
      aboutText(format: "html", locale: $locale)
      info(locale: $locale)
      logoHeader(locale: $locale)
      partnerHeader(locale: $locale)
      partnerInfo(locale: $locale)
      subtitle(locale: $locale)
      title(locale: $locale)
      url
      whiteLabel
      styles {
        accentColor
        accentTextColor
        backgroundColor
        backgroundTextColor
        highlightColor
        fontLink
        fontName
        titleFontLink
        titleFontName
      }
      logoImage(locale: $locale) {
        imageUrl(width: 500)
      }
      pageLogoImage(locale: $locale) {
        imageUrl(width: 500)
      }
      titleImage(locale: $locale) {
        imageUrl(width: 500)
      }
      backgroundImages {
        id
        imageUrl(width: 1000)
      }
      partnerLogoImages(locale: $locale) {
        id
        imageUrl(height: 192)
        url
      }
    }
  }
`

export const groupMentorsQuery = gql`
  query GroupMembers($id: ID, $locale: String) {
    group(id: $id) {
      id

      slug
      whiteLabel
      styles {
        accentColor
        accentTextColor
        backgroundColor
        backgroundTextColor
        highlightColor
        fontLink
        fontName
        titleFontLink
        titleFontName
      }
      mentors {
        id
        cancellationPolicy
        disciplines {
          id
          name(locale: $locale)
        }
        group {
          id
          name
          tag
        }
        name
        availabilities {
          allowGroupSessions
          endTime
          id
          startTime
        }
        timeSlots {
          allowGroupSessions
          endTime
          id
          startTime
        }
        sessionLengths
        profileImageUrl
        publicTagList(locale: $locale)
        role
        location
        description
        slug
        tier
      }
    }
  }
`

export const groupMembersQuery = gql`
  query groupMembers($groupId: ID!) {
    group(id: $groupId) {
      members {
        id
        name
      }
    }
  }
`

export const groupMemberQuery = gql`
  query groupMember(
    $groupId: ID
    $id: ID!
    $locale: String
    $untilDate: ISO8601DateTime
  ) {
    group(id: $groupId) {
      id
      slug
      styles {
        accentColor
        accentTextColor
        backgroundColor
        backgroundTextColor
        highlightColor
        fontLink
        fontName
        titleFontLink
        titleFontName
      }
      mentorMaxSessions
      menteeMaxSessions
      sessionLengths
      whiteLabel
      logoImage {
        imageUrl
        fileUrl
      }
      pageLogoImage {
        imageUrl
        fileUrl
      }
      member(id: $id) {
        id
        allowGroupSessions
        avatar {
          id
          color
          imageUrl(width: 80, height: 80)
          initials
        }
        company
        bookings(segment: "future") {
          id
          mentor {
            id
          }
          mentee {
            id
          }
          startTime
          endTime
        }
        cancellationPolicy
        description
        disciplines {
          id
          name(locale: $locale)
        }
        experience
        group {
          id
          name
          tag
        }
        groupSessions {
          id
          groupSession
          title
          startTime
          endTime
          description
          id
          isFull
          maxParticipants
          participants {
            id
          }
        }
        languages {
          code
          id
          name
        }
        location
        profileImageUrl(width: 400, height: 400)
        publicTagList(locale: $locale)
        menteeSessionsRemaining
        mentorSessionsRemaining
        rates
        role
        sessionLengths
        slug
        subdisciplines {
          id
          name
        }
        availabilities(endTime: $untilDate) {
          allowGroupSessions
          endTime
          id
          startTime
        }
        timeSlots {
          allowGroupSessions
          endTime
          id
          startTime
        }
        name
        role
        skills
        socialLinks {
          type
          url
        }
        tier
        website
        welcomeMessage
      }
    }
  }
`
export const disciplinesQuery = gql`
  query disciplines($locale: String) {
    disciplines(locale: $locale) {
      id
      imageUrl
      name(locale: $locale)
      slug
      subdisciplines(locale: $locale) {
        id
        name(locale: $locale)
        slug
      }
    }
  }
`
