import classNames from 'classnames'
import PropTypes from 'prop-types'
import { X as CloseIcon } from 'react-feather'

export const CloseButton = ({ className, onClose }) => {
  return (
    <button onClick={onClose}>
      <CloseIcon
        className={classNames('hover:text-darkerGray', className)}
        size={16}
        strokeWidth={3}
      />
    </button>
  )
}

CloseButton.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
}

export default CloseButton
