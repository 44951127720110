import classNames from 'classnames'
import CloseButton from 'components/general/CloseButton'
import PropTypes from 'prop-types'
import {
  AlertCircle as WarningIcon,
  CheckCircle as SuccessIcon,
  Info as InfoIcon,
  XCircle as ErrorIcon,
} from 'react-feather'

const types = {
  error: {
    borderClassName: 'border-red bg-lightRed',
    iconClassName: 'text-red',
    icon: ErrorIcon,
  },
  info: {
    borderClassName: 'border-blue bg-lightBlue',
    iconClassName: 'text-blue',
    icon: InfoIcon,
  },
  success: {
    borderClassName: 'border-green bg-lightGreen',
    iconClassName: 'text-green',
    icon: SuccessIcon,
    title: 'Success',
  },
  warning: {
    borderClassName: 'border-orange bg-lightOrange',
    iconClassName: 'text-orange',
    icon: WarningIcon,
  },
  subtle: {
    borderClassName: 'border-none bg-gray',
    iconClassName: 'text-evenDarkerGray',
    icon: InfoIcon,
  },
}

const Alert = ({
  children,
  className,
  description,
  closable,
  onClose,
  showIcon,
  title,
  type,
}) => {
  if (!children && !description && !title) return null
  const Icon = types[type]?.icon
  const { borderClassName, iconClassName } = types[type] || types.info
  const textColor = iconClassName

  return (
    <div
      className={classNames(
        'border rounded flex pl-4 pr-6 py-3 text-black',
        borderClassName,
        className
      )}
    >
      {showIcon && (
        <div className="my-1 mr-3">
          <Icon className={iconClassName} size={title ? 24 : 16} />
        </div>
      )}
      <div className="w-full">
        {title && <div className="text-lg leading-8">{title}</div>}
        <div className={textColor}>{children || description}</div>
      </div>
      {closable && (
        <div>
          <CloseButton className={iconClassName} onClose={onClose} />
        </div>
      )}
    </div>
  )
}

Alert.defaultProps = {
  type: 'info',
}

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.node,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  showIcon: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.oneOf(['info', 'error', 'success', 'warning', 'subtle']),
}

export default Alert
